<template>
  <div class="AssistancePreview">
    <p class="AssistancePreview__title">{{ label }}</p>
    <div class="AssistancePreview__row">
      <label class="AssistancePreview__label">Volet activité</label>
      <div class="AssistancePreview__amount">{{ currentValue.api1 | formatPrice }}</div>
    </div>
    <div class="AssistancePreview__row AssistancePreview__row--folder">
      <div class="AssistancePreview__row">
        <label class="AssistancePreview__label">Volet Emploi - Comédiens</label>
        <div class="AssistancePreview__amount">{{ currentValue.api2 | formatPrice }}</div>
      </div>
      <div class="AssistancePreview__row">
        <label class="AssistancePreview__label">Volet Emploi - Techniciens plateau</label>
        <div class="AssistancePreview__amount">{{ currentValue.api3 | formatPrice }}</div>
      </div>
      <div class="AssistancePreview__row AssistancePreview__row--subTotal">
        <label class="AssistancePreview__label">Total volet emploi</label>
        <div class="AssistancePreview__amount">{{ currentValue.api4 | formatPrice }}</div>
      </div>
    </div>
    <div class="AssistancePreview__row AssistancePreview__row--subTotal">
      <label class="AssistancePreview__label">Total final d'APIT</label>
      <div class="AssistancePreview__amount">{{ currentValue.api5 | formatPrice }}</div>
    </div>
    <div class="AssistancePreview__row AssistancePreview__row--total">
      <label class="AssistancePreview__label">Plafond d'aide théorique (70% du bilan financier)</label>
      <div class="AssistancePreview__amount">{{ currentValue.api6 | formatPrice }}</div>
    </div>
    <div class="AssistancePreview__row AssistancePreview__row--total">
      <label class="AssistancePreview__label">Montant restant dû au tourneur</label>
      <div class="AssistancePreview__amount">{{ currentValue.api7 | formatPrice }}</div>
    </div>
    <div class="AssistancePreview__row AssistancePreview__row--total">
      <label class="AssistancePreview__label">Montant restant dû à l'ASTP</label>
      <div class="AssistancePreview__amount">{{ currentValue.api8 | formatPrice }}</div>
    </div>
  </div>
</template>

<script>
import { baseMixin } from "@npm/omega-front";
import formatPrice from "../formatPrice";

export default {
  mixins: [baseMixin],
  filters: { formatPrice }
};
</script>
