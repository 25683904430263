<template>
  <div class="container">
    <div class="Spinner" v-if="!formIsReady"></div>
    <Stepper
      v-show="formIsReady"
      :currentStep="currentStep"
      :stepCount="3"
    />
    <OmegaForm
      v-show="formIsReady"
      url="/funding-request-balance"
      :id="id"
      :customComponents="customComponents"
      :stepLabel="stepLabel"
      previousStepLabel="Retour"
      :nextStepLabel="nextStepLabel"
      submitLabel="Enregistrer les modifications"
      :extraSubmits="{
        approve: {
          label: 'Envoyer le bilan à l’ASTP',
          confirm: 'Vous allez envoyer votre bilan de tournée à l’ASTP. Vous ne pourrez plus revenir sur les données saisies, seul l’administrateur de l’ASTP en aura la possibilité.',
          payload: { status: 'balance_validation' }
        }
      }"
      @initEnd="handleInit"
      @fullSubmit="handleSubmit"
      @stepChanged="updateStep"
    />
  </div>
</template>

<script>
import { OmegaForm } from "@npm/omega-front";
import formMixin from "../formMixin";
import Stepper from "./Stepper";

export default {
  mixins: [formMixin],
  components: { OmegaForm, Stepper }
};
</script>
