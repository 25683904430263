<template>
  <div class="container">
    <div class="Spinner" v-if="!formIsReady"></div>
    <OmegaForm
      v-show="formIsReady"
      url="/validate-funding-request"
      :id="id"
      :stepped="false"
      :customComponents="customComponents"
      submitLabel="Enregistrer les modifications"
      :extraSubmits="{
        reject: {
          label: 'Refuser la demande',
          confirm: 'Êtes-vous sûr·e de votre choix ?',
          payload: { status: 'rejected' }
        },
        approve: {
          label: 'Accepter la demande',
          confirm: 'Êtes-vous sûr·e de votre choix ?',
          payload: { status: 'approved' }
        }
      }"
      @initEnd="handleInit"
      @fullSubmit="handleSubmit"
    />
  </div>
</template>

<script>
import { OmegaForm } from "@npm/omega-front";
import formMixin from "../formMixin";

export default {
  mixins: [formMixin],
  components: { OmegaForm }
};
</script>
