/**
 * @param {Number} [delay]
 */
export default function handleNotification(delay = 4000) {
  const notification = document.getElementById("notification");

  if (!notification) {
    return;
  }

  notification.querySelector(".js-close").addEventListener("click", () => {
    notification.classList.add("is-hidden");
  });

  window.setTimeout(() => {
    notification.classList.add("is-hidden");
  }, delay);
}
