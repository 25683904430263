<template>
  <div v-if="!ide2">
    <Autocomplete
      v-bind="$props"
      v-model="currentValue"
      :disabled="!ide3"
    />
  </div>
</template>

<script>
import { baseMixin, Autocomplete } from "@npm/omega-front";

export default {
  mixins: [baseMixin],

  props: {
    ide3: {
      type: [Boolean, Object],
      required: true
    },

    ide2: {
      type: [Boolean, Object],
      required: true
    },

    endpoint: {
      type: String,
      required: true
    }
  },

  watch: {
    ide3(ide3) {
      if (!ide3) {
        this.currentValue = null;
      }
    }
  },

  components: { Autocomplete }
};
</script>
