<template>
  <div class="TourDetail" :class="wrapperClass">
    <Number
      :label="labels.car3"
      v-model.number="localValue.car3"
      name="car3"
      cssClass="mbs"
      :disabled="disabled"
    />
    <Number
      :label="labels.car4"
      v-model.number="localValue.car4"
      name="car4"
      :max="localValue.car3"
      cssClass="mbs mlm"
      :disabled="disabled"
    />
    <Number
      :label="labels.car5"
      v-model.number="localValue.car5"
      name="car5"
      :max="localValue.car3"
      cssClass="mbs mlm"
      :disabled="disabled"
    />
    <Number
      :label="labels.car6"
      v-model.number="localValue.car6"
      name="car6"
      :max="localValue.car3"
      cssClass="mbs mlm"
      :disabled="disabled"
    />
    <Number
      :label="labels.car7"
      v-model.number="localValue.car7"
      name="car7"
      :max="localValue.car3"
      cssClass="mbs mlm"
      :disabled="disabled"
      :optional="true"
    />
    <div class="invalid-feedback mbs" v-if="error">{{ error }}</div>
    <div class="invalid-feedback mbs" v-if="totalErrors">{{ totalErrors }}</div>
    <div class="invalid-feedback mbs" v-if="avignonErrors">{{ avignonErrors }}</div>
    <div class="invalid-feedback mbs" v-if="successiveErrors">{{ successiveErrors }}</div>
    <div v-show="!isGranted" class="invalid-feedback mbs">Le nombre minimum de représentations nécessaires à l'éligibilité aux aides n'est pas atteint, la validation du dépot sera bloquée.</div>
  </div>
</template>

<script>
import { baseMixin, Number } from "@npm/omega-front";
import labels from "../labels";

function hasValues(obj) {
  return obj && !("initialValue" in obj) && Object.keys(obj).some(key => obj[key] !== 0);
}

const defaultValue = {
  car3: 0,
  car4: 0,
  car5: 0,
  car6: 0,
  car7: 0
};

export { defaultValue };

export default {
  mixins: [baseMixin],

  data() {
    return {
      localValue: defaultValue
    };
  },

  computed: {
    labels() {
      return labels;
    },

    totalErrors() {
      const { car3, car4, car5, car6 } = this.localValue;

      if (car3 < car4 + car5 + car6) {
        return "Le total n'est pas cohérent avec le détail de votre tournée";
      }

      return "";
    },

    avignonErrors() {
      if (this.localValue.car4 !== 0 && this.localValue.car4 < 15) {
        return "Le nombre de représentations à Avignon doit être nul ou supérieur à 14";
      }

      return "";
    },

    successiveErrors() {
      if (this.localValue.car7 && this.localValue.car7 < 5) {
        return "Le nombre de représentations successives doit être supérieur à 5";
      }

      return "";
    },

    // Nombre de représentations minimum atteint
    isGranted() {
      const { car3, car4, car5, car6 } = this.localValue;

      return (car4 === 0 && car3 - car5 >= 15)
        || (car4 >= 15 && car3 - car4 >= 10);
    },

    stringifiedLocalValue() {
      return JSON.stringify(this.localValue);
    }
  },
  watch: {
    value: {
      handler(newValue, oldValue) {
        const hasOldValue = hasValues(oldValue);

        // Override historyMixin behaviour, as default values are added before we get the payload
        if (!hasOldValue && newValue && typeof newValue === "object" && "initialValue" in newValue) {
          this.initialValue = newValue.initialValue;
          this.updatedAt = newValue.updatedAt;
          this.setValueSynthetic(newValue.value);

          return;
        }

        if (!hasOldValue && !hasValues(this.localValue) && hasValues(newValue)) {
          this.localValue = newValue;
        }
      },

      immediate: true,
      deep: true
    },

    stringifiedLocalValue: {
      handler(newValue, oldValue) {
        if (oldValue && hasValues(JSON.parse(oldValue)) && newValue !== oldValue) {
          this.currentValue = { ...this.localValue };
        }
      },

      immediate: true,
      deep: true
    }
  },

  components: { Number }
};
</script>
