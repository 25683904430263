import { isValue } from "@npm/omega-front";
import eventBus from "./eventBus";

export default {
  data() {
    return {
      initialValue: null,
      updatedAt: "",
      beforeTourValue: null,
      beforeTourInitialValue: null,
      beforeTourUpdatedAt: ""
    };
  },

  computed: {
    // Overridden from Omega's baseMixin to disallow rendering "[object Object]"
    // Displaying past values as text does not really make sense for an object anyway
    currentValue: {
      get() {
        if (isValue(this.value) && typeof this.value !== "object") {
          return this.value;
        }

        return this.defaultValue;
      },

      set(newValue) {
        this.$emit("change", newValue);
        this.$emit("input", newValue);
      }
    }
  },

  watch: {
    value: {
      handler(newValue, oldValue) {
        // Retrieve history metadata and overwrite value inside Omega with plain one
        if (newValue && typeof newValue === "object" && "initialValue" in newValue) {
          this.initialValue = newValue.initialValue;
          this.updatedAt = newValue.updatedAt;
          this.setValueSynthetic(newValue.value);

          if ("beforeTour" in newValue) {
            this.beforeTourValue = newValue.beforeTour.value;
            this.beforeTourInitialValue = newValue.beforeTour.initialValue;
            this.beforeTourUpdatedAt = newValue.beforeTour.updatedAt;

            // Emit event to help handle some edge cases
            eventBus.$emit(`beforeTour.${this.name}`, newValue.beforeTour.value);
          }
        }
      },

      immediate: true,
      deep: true
    }
  }
};
