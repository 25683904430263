<template>
  <div class="Input" :class="wrapperClass">
    <div class="Input__label">{{ label }}</div>
    <Productor
      v-for="(productor, index) in localValue"
      :key="index"
      v-bind="productor"
      :readonly="readonly"
      :disabled="disabled"
      :canRemove="canRemove(productor)"
      @remove="remove(index)"
      @update="update($event, index)"
    />
    <div class="invalid-feedback" v-if="allErrors">{{ allErrors }}</div>
  </div>
</template>

<script>
import { baseMixin } from "@npm/omega-front";
import Productor from "./Productor";

export default {
  mixins: [baseMixin],

  props: {
    mainProductor: {
      type: String,
      default: ""
    }
  },

  data() {
    return { localValue: [] };
  },

  computed: {
    allErrors() {
      return this.error || this.internalError;
    },

    validCount() {
      if (!Array.isArray(this.localValue)) {
        return 0;
      }

      return this.localValue.filter(value => value.name && value.percent).length;
    },

    totalPercent() {
      return this.localValue.reduce((total, value) => total + (value.percent ? parseFloat(value.percent) : 0), 0);
    },

    internalError() {
      if (this.validCount > 0 && this.totalPercent !== 100) {
        return "Le total doit être égal à 100%.";
      }

      const main = this.localValue.find(productor => productor.locked);

      if (main && main.percent < 50) {
        return "Le pourcentage du tourneur doit être au moins de 50%.";
      }

      return "";
    },

    stringifiedLocalValue() {
      return JSON.stringify(this.localValue);
    }
  },

  watch: {
    value: {
      handler(newValue, oldValue) {
        if (JSON.stringify(oldValue) === JSON.stringify(newValue)) {
          return;
        }

        const hasOldValue = Array.isArray(oldValue)
          && oldValue.filter(p => p.name.length > 0 || p.percent > 0).length > 0;

        // Override historyMixin behaviour, as a coproductor is added before we get the payload
        if (!hasOldValue && newValue && typeof newValue === "object" && "initialValue" in newValue) {
          this.initialValue = newValue.initialValue;
          this.updatedAt = newValue.updatedAt;
          this.setValueSynthetic(newValue.value);

          return;
        }

        if (!hasOldValue && this.localValue.length <= 1) {
          if (Array.isArray(newValue)) {
            this.localValue = newValue;
          }

          while (this.localValue.length < 1) {
            this.addCoproductor();
          }
        }
      },

      immediate: true,
      deep: true
    },

    stringifiedLocalValue(newValue, oldValue) {
      if (newValue !== oldValue && Array.isArray(this.localValue)) { // actual change?
        const newValue = this.localValue.filter(productor => productor.name.length > 0 && productor.percent > 0);

        if (this.currentValue === null) {
          this.setValueSynthetic(newValue);
        } else {
          this.currentValue = newValue;
        }
      }
    },

    totalPercent(percent) {
      this.localValue = this.localValue.filter(productor => productor.name.length > 0);

      if (percent < 100) {
        this.addCoproductor();
      }
    }
  },

  methods: {
    addCoproductor() {
      if (this.mainProductor.length > 0 && this.localValue.length === 0) {
        this.localValue.push({ name: this.mainProductor, percent: 50, locked: true });
      }

      this.localValue.push({ name: "", percent: null });
    },

    canRemove(productor) {
      return this.localValue.length > 1 && !!productor.name && !!productor.percent;
    },

    update({ field, value }, index) {
      this.localValue[index][field] = value;
    },

    remove(index) {
      if (this.localValue.length === 1) {
        return;
      }

      this.localValue.splice(index, 1);
    }
  },

  components: { Productor }
};
</script>
