<template>
  <Datepicker
    v-bind="props"
    v-model="currentValue"
  />
</template>

<script>
import { baseMixin, Datepicker } from "@npm/omega-front";
import { isValid as isValidFrench, parse as parseFrench } from "@npm/french-date";

export default {
  mixins: [baseMixin],

  props: {
    allowPast: {
      type: Boolean,
      default: true
    },

    min: {
      type: [String, Object],
      default: ""
    }
  },

  computed: {
    props() {
      const hasMin = typeof this.min === "string" && this.min;
      let max = "";

      if (hasMin) {
        max = new Date(isValidFrench(this.min) ? parseFrench(this.min) : this.min);
        max.setHours(0);
        max.setMinutes(0);
        max.setSeconds(0);
        max.setDate(max.getDate() + 548); // 18 months, pretty much
        max = max.toISOString();
      }

      return {
        ...this.$props,
        min: hasMin ? this.min : "",
        max
      };
    }
  },

  components: { Datepicker }
};
</script>
