import handleNotification from "./handleNotification";

/**
 * @param {String} message
 * @param {String} [level]
 * @param {Number} [delay]
 */
export default function showNotification(message, level = "notice", delay = undefined) {
  const section = document.querySelector(".notification__section");

  if (!section) {
    return;
  }

  let notification = document.getElementById("notification");

  if (notification) {
    notification.classList.remove(...notification.classList);

    while (notification.firstChild) {
      notification.removeChild(notification.firstChild);
    }
  } else {
    notification = document.createElement("div");
    notification.id = "notification";
  }

  const msgElement = document.createElement("div");
  const close = document.createElement("div");

  notification.classList.add("notification__item");
  notification.classList.add(`notification__item--${level}`);
  msgElement.classList.add("notification__message");
  close.classList.add("notification__close");
  close.classList.add("js-close");
  close.innerHTML = "×";

  notification.appendChild(msgElement);
  notification.appendChild(close);
  section.appendChild(notification);

  msgElement.innerHTML = message;

  handleNotification(delay);
}
