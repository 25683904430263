<template>
  <div :class="wrapperClass" class="AmountTotal">
    <div class="AmountTotal__label">{{ label }}</div>
    <div class="AmountTotal__count">{{ total | formatPrice }}</div>
  </div>
</template>

<script>
import { baseMixin } from "@npm/omega-front";
import historyMixin from "../historyMixin";
import formatPrice from "../formatPrice";

const fieldProps = [
  "rec1", "rec2", "rec3", "rec4", "rec5",
  "tec1", "tec2", "tec3", "tec4", "tec5", "tec6", "tec7",
  "per1", "per2", "per3", "per4", "per5", "per6", "per7", "per8",
  "tra1", "tra2", "tra3", "tra4", "tra5",
  "tecb1", "tecb2", "tecb3", "tecb4", "tecb5", "tecb6",
  "perb1", "perb2", "perb3", "perb4", "perb5", "perb6", "perb7", "perb8",
  "trab1", "trab2", "trab3", "trab4",
  "dro1", "dro2", "dro3", "dro4",
  "mon1", "exp1", "eom1"
].reduce((props, key) => ({
  ...props,
  [key]: {
    type: [Number, String, Object],
    default: 0
  }
}), {
  category: {
    type: String,
    default: ""
  }
});

export { fieldProps };

export default {
  mixins: [baseMixin, historyMixin],

  props: {
    ...fieldProps,

    fixedTotal: {
      type: Number,
      default: null
    }
  },

  computed: {
    total() {
      return this.fixedTotal === null
        ? Math.round(this.budgetLines.reduce((total, value) => total + (isNaN(parseFloat(value)) ? 0 : value), 0) * 100) / 100
        : this.fixedTotal;
    },
    budgetLines() {
      if (this.name === "mon1") {
        return [this.tec7, this.per8, this.tra5];
      }

      if (this.name === "exp1") {
        return [this.tecb6, this.perb8, this.trab4, this.dro4];
      }

      if (this.name === "dep1") {
        return [this.mon1, this.exp1, this.eom1];
      }

      return Object.keys(this.$props).filter(p => this.regEx.test(p))
        .map(p => this.$props[p]);
    },
    regEx() {
      return new RegExp("^" + this.category + "[1-9]{1,2}$");
    }
  },
  watch: {
    total: {
      handler(value) {
        this.setValueSynthetic(value);
      },

      immediate: true
    }
  },

  filters: { formatPrice }
};
</script>
