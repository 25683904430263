<template>
  <div class="Stepper">
    <div class="Stepper__steps">
      <div v-for="index in stepCount" :key="index" class="Stepper__step" :class="{'is-active': index <= currentStep}">
        <div class="Stepper__step-inner">
          <div class="Stepper__circle">
            <span>{{ index }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="label" class="Stepper__label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  props: {
    currentStep: {
      type: Number,
      default: 1
    },
    stepCount: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      default: ""
    }
  }
};
</script>
