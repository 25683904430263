<template>
  <div class="Productor">
    <div class="Input">
      <input
        type="text"
        :readonly="readonly"
        :disabled="disabled || locked"
        :value="name"
        @input="$emit('update', { field: 'name', value: $event.target.value})"
        class="Input__field"
      />
    </div>
    <div class="Input--suffix" data-suffix="%">
      <input
        type="number"
        :min="locked ? 50 : 0"
        max="100"
        step="0.01"
        :readonly="readonly"
        :disabled="disabled"
        :value="percent"
        @input="$emit('update', { field: 'percent', value: parseFloat($event.target.value)})"
        class="Input__field"
      />
    </div>
    <div v-show="canRemove && !disabled && !locked" class="Productor__remove" @click="$emit('remove')"></div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },

    percent: {
      validator: prop => typeof prop === "number" || prop === null,
      required: true
    },

    locked: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    canRemove: {
      type: Boolean,
      required: true
    }
  }
};
</script>
