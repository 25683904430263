<template>
  <div class="HistoryContainer">
    <div class="Input" :class="wrapperClass">
      <label class="Input__label">{{ label }}</label>
      <div v-if="extraInfo" class="Input__extra-info">{{ extraInfo }}</div>
      <vue-dropzone
        :useCustomSlot="true"
        ref="dropzone"
        id="dropzone"
        :options="options"
        class="Uploader"
        @vdropzone-success="update"
      >
        <p class="Uploader__text">Glissez votre fichier ici</p>
        <div class="Uploader__button Button Button--primary Button--invert">Envoyer un fichier</div>
      </vue-dropzone>
      <ul class="Uploader__files">
        <li v-if="beforeTourValue && beforeTourValue.url">
          <b>Au dépôt du dossier :</b>
          <a :href="beforeTourValue.url" target="_blank">{{ beforeTourValue.filename }}</a>
        </li>
        <li v-if="value && value.url">
          <b v-if="beforeTourValue && beforeTourValue.url">À la saisie du bilan :</b>
          <a :href="value.url" target="_blank">{{ value.filename }}</a>
        </li>
      </ul>
      <div class="invalid-feedback" v-if="error">{{ error }}</div>
    </div>
    <HistoryTooltip
      v-if="initialValue"
      :initialValue="initialValue.filename"
      :updatedAt="updatedAt"
      :href="initialValue.url"
    />
  </div>
</template>

<script>
import VueDropzone from "vue2-dropzone";
import { baseMixin } from "@npm/omega-front";
import historyMixin from "../historyMixin";
import HistoryTooltip from "./HistoryTooltip";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  mixins: [baseMixin, historyMixin],

  props: {
    endpoint: {
      type: String,
      required: true
    },

    paramName: {
      type: String,
      default: "upload[file]"
    },

    extraInfo: {
      type: String,
      default: ""
    }
  },

  computed: {
    options() {
      return {
        url: this.endpoint,
        paramName: this.paramName,
        maxFiles: 1,
        addRemoveLinks: !this.disabled && !this.readonly,
        dictRemoveFile: "Supprimer"
      };
    }
  },

  watch: {
    value: {
      handler(newValue, oldValue) {
        if (!("dropzone" in this.$refs) || !newValue || "initialValue" in newValue
          || (oldValue && "id" in oldValue && newValue && "id" in newValue && oldValue.id === newValue.id)
        ) {
          return;
        }

        this.addFile(newValue);
      },

      immediate: true,
      deep: true
    }
  },

  methods: {
    update(file) {
      const { id } = JSON.parse(file.xhr.responseText);

      this.$emit("change", { id });
    },

    addFile(file) {
      if (["filename", "url"].every(key => key in file && file[key])) {
        this.$refs.dropzone.manuallyAddFile({ name: file.filename, size: 0 }, file.url);
      }
    }
  },

  mounted() {
    if (this.disabled) {
      this.$refs.dropzone.disable();
    }
  },

  components: { VueDropzone, HistoryTooltip }
};
</script>
