<template>
  <div class="HistoryContainer HistoryContainer--beforeTour">
    <AmountTotal
      :name="name"
      :cssClass="cssClass"
      :fixedTotal="beforeTourValue"
    />
    <AmountTotal
      v-bind="$props"
      @change="value => setValueSynthetic(value)"
    />
  </div>
</template>

<script>
import { baseMixin } from "@npm/omega-front";
import historyMixin from "../historyMixin";
import AmountTotal, { fieldProps } from "./AmountTotal";

export default {
  mixins: [baseMixin, historyMixin],
  props: fieldProps,
  components: { AmountTotal }
};
</script>
