<template>
  <div class="HistoryValue">
    <span v-if="caption.length > 0" class="HistoryValue__caption">{{ caption }}</span>

    <table v-if="isArray" class="HistoryValue__table">
      <tr>
        <td v-for="(item, index) in value" :key="index">
          <HistoryValue :value="item" />
        </td>
      </tr>
    </table>

    <table v-else-if="isObject" class="HistoryValue__table">
      <tr v-for="key in Object.keys(value)" :key="key">
        <th>{{ labelFor(key) }}</th>
        <td>
          <HistoryValue :value="value[key]" />
        </td>
      </tr>
    </table>

    <a v-else-if="href" :href="href" target="_blank">{{ value }}</a>
    <span v-else class="HistoryValue__plain">{{ value | labelize }}</span>
  </div>
</template>

<script>
import { isValue } from "@npm/omega-front";
import labels from "../labels";

export default {
  name: "HistoryValue",

  props: {
    value: {
      type: null,
      required: true
    },

    caption: {
      type: String,
      default: ""
    },

    href: {
      type: String,
      default: ""
    }
  },

  computed: {
    isArray() {
      return Array.isArray(this.value);
    },

    isObject() {
      return typeof this.value === "object" && this.value !== null;
    }
  },

  methods: {
    labelFor(key) {
      return key in labels ? labels[key] : key;
    }
  },

  filters: {
    labelize(value) {
      if (typeof value === "boolean") {
        return value ? "Oui" : "Non";
      }

      // undefined, null, NaN, "", [], {}
      if (!isValue(value)
        || value === ""
        || (Array.isArray(value) && value.length === 0)
        || (typeof value === "object" && Object.keys(value).length === 0)
      ) {
        return "(vide)";
      }

      return value;
    }
  }
};
</script>
