import showNotification from "./showNotification";

export default {
  props: {
    id: {
      type: Number,
      default: null
    },

    customComponents: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      readonly: true,
      formIsReady: false,
      currentStep: 1
    };
  },

  computed: {
    stepLabel() {
      return this.readonly ? "" : "Toute progression sera sauvegardée";
    },

    nextStepLabel() {
      return this.readonly ? "Suite" : "Étape suivante";
    }
  },

  methods: {
    handleInit({ definition }) {
      this.readonly = "overrides" in definition && definition.overrides.disabled;
      this.formIsReady = true;
    },

    handleSubmit(payload, redirect) {
      if (redirect) {
        window.location.href = "/fundings";
      } else {
        showNotification("Les modifications ont bien été enregistrées", "success");
      }
    },

    updateStep(index) {
      this.currentStep = index + 1;
    }
  }
};
