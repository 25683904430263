import Vue from "vue";

const bus = new Vue();
const lastValues = {};
const originalEmit = bus.$emit.bind(bus);
const originalOn = bus.$on.bind(bus);

bus.$emit = function(name, value) {
  lastValues[name] = value;
  originalEmit(name, value);
};

bus.$on = function(name, callback) {
  if (lastValues[name] !== undefined) {
    callback(lastValues[name]);
  }

  originalOn(name, callback);
};

export default bus;
