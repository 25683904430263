var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(!_vm.formIsReady)?_c('div',{staticClass:"Spinner"}):_vm._e(),_vm._v(" "),_c('Stepper',{directives:[{name:"show",rawName:"v-show",value:(_vm.formIsReady),expression:"formIsReady"}],attrs:{"currentStep":_vm.currentStep,"stepCount":3}}),_vm._v(" "),_c('OmegaForm',{directives:[{name:"show",rawName:"v-show",value:(_vm.formIsReady),expression:"formIsReady"}],attrs:{"url":"/funding-request","id":_vm.id,"customComponents":_vm.customComponents,"stepLabel":_vm.stepLabel,"previousStepLabel":"Retour","nextStepLabel":_vm.nextStepLabel,"submitLabel":"Enregistrer les modifications","extraSubmits":{
      approve: {
        label: 'Envoyer la demande à l’ASTP',
        confirm: 'Vous allez envoyer votre demande d’aide à l’ASTP. Vous ne pourrez plus revenir sur les données saisies, seul l’administrateur de l’ASTP en aura la possibilité. Vous reprendrez la main sur le dossier dès la date prévisionnelle de fin de tournée, pour la saisie du bilan.',
        payload: { status: 'pending' }
      }
    }},on:{"initEnd":_vm.handleInit,"fullSubmit":_vm.handleSubmit,"stepChanged":_vm.updateStep,"wizardId":_vm.goToEditMode}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }