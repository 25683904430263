<template>
  <div class="container">
    <div class="Spinner" v-if="!formIsReady"></div>
    <Stepper
      v-show="formIsReady"
      :currentStep="currentStep"
      :stepCount="3"
    />
    <OmegaForm
      v-show="formIsReady"
      url="/funding-request"
      :id="id"
      :customComponents="customComponents"
      :stepLabel="stepLabel"
      previousStepLabel="Retour"
      :nextStepLabel="nextStepLabel"
      submitLabel="Enregistrer les modifications"
      :extraSubmits="{
        approve: {
          label: 'Envoyer la demande à l’ASTP',
          confirm: 'Vous allez envoyer votre demande d’aide à l’ASTP. Vous ne pourrez plus revenir sur les données saisies, seul l’administrateur de l’ASTP en aura la possibilité. Vous reprendrez la main sur le dossier dès la date prévisionnelle de fin de tournée, pour la saisie du bilan.',
          payload: { status: 'pending' }
        }
      }"
      @initEnd="handleInit"
      @fullSubmit="handleSubmit"
      @stepChanged="updateStep"
      @wizardId="goToEditMode"
    />
  </div>
</template>

<script>
import { OmegaForm } from "@npm/omega-front";
import formMixin from "../formMixin";
import Stepper from "./Stepper";

export default {
  mixins: [formMixin],

  methods: {
    goToEditMode(id) {
      history.replaceState({}, null, `/fundings/${id}`);
    }
  },

  components: { OmegaForm, Stepper }
};
</script>
