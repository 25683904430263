var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(!_vm.formIsReady)?_c('div',{staticClass:"Spinner"}):_vm._e(),_vm._v(" "),_c('OmegaForm',{directives:[{name:"show",rawName:"v-show",value:(_vm.formIsReady),expression:"formIsReady"}],attrs:{"url":"/validate-funding-request","id":_vm.id,"stepped":false,"customComponents":_vm.customComponents,"submitLabel":"Enregistrer les modifications","extraSubmits":{
      reject: {
        label: 'Refuser la demande',
        confirm: 'Êtes-vous sûr·e de votre choix ?',
        payload: { status: 'rejected' }
      },
      approve: {
        label: 'Accepter la demande',
        confirm: 'Êtes-vous sûr·e de votre choix ?',
        payload: { status: 'approved' }
      }
    }},on:{"initEnd":_vm.handleInit,"fullSubmit":_vm.handleSubmit}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }