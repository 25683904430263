<template>
  <div v-if="!ide2">
    <RadioGroup v-bind="$props" v-model="currentValue" />
  </div>
</template>

<script>
import { baseMixin, RadioGroup } from "@npm/omega-front";

export default {
  mixins: [baseMixin],
  props: {
    ide2: {
      type: [Boolean, Object],
      required: true
    }
  },
  watch: {
    ide2(ide2) {
      if (ide2) {
        this.setValueSynthetic(false);
      }
    }
  },
  components: {
    RadioGroup
  }
};
</script>
