<template>
  <Number
    name="car9"
    label="Nombre de représentations pour le calcul des frais d'EOM"
    :value="car9"
    :readonly="true"
    :disabled="disabled"
    :optional="optional"
    class="Input--row"
    :cssClass="cssClass"
  />
</template>

<script>
import { baseMixin, Number } from "@npm/omega-front";

export default {
  mixins: [baseMixin],

  props: {
    tour: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    // Quotient representations fixes
    fixedPerformance() {
      if (this.tour.car7 <= 25) {
        return this.tour.car3 - this.tour.car4;
      }

      if (this.tour.car7 <= 50) {
        return this.tour.car3 - this.tour.car4 - (this.tour.car7 - 25) / 2;
      }

      return this.tour.car3 - this.tour.car4 - this.tour.car7 + 37.5;
    },

    car9() {
      return Math.max(this.fixedPerformance, 0);
    }
  },

  watch: {
    car9(value) {
      this.setValueSynthetic(value);
    }
  },

  components: { Number }
};
</script>
