<template>
  <div class="AssistancePreview">
    <div class="AssistancePreview__row">
      <label class="AssistancePreview__label">Volet activité</label>
      <div class="AssistancePreview__amount">{{ aco1 | formatPrice }}</div>
    </div>
    <div class="AssistancePreview__row AssistancePreview__row--folder">
      <div class="AssistancePreview__row">
        <label class="AssistancePreview__label">Volet Emploi - Comédiens</label>
        <div class="AssistancePreview__amount">{{ aco2 | formatPrice }}</div>
      </div>
      <div class="AssistancePreview__row">
        <label class="AssistancePreview__label">Volet Emploi - Techniciens plateau</label>
        <div class="AssistancePreview__amount">{{ aco3 | formatPrice }}</div>
      </div>
      <div class="AssistancePreview__row AssistancePreview__row--subTotal">
        <label class="AssistancePreview__label">Total volet emploi</label>
        <div class="AssistancePreview__amount">{{ aco4 | formatPrice }}</div>
      </div>
    </div>
    <div class="AssistancePreview__row AssistancePreview__row--subTotal">
      <label class="AssistancePreview__label">Total d'aide théorique</label>
      <div class="AssistancePreview__amount">{{ aco5 | formatPrice }}</div>
    </div>
    <div class="AssistancePreview__row AssistancePreview__row--total">
      <label class="AssistancePreview__label">Acompte à verser (60% de l'aide théorique)</label>
      <div class="AssistancePreview__amount">{{ aco6 | formatPrice }}</div>
    </div>
  </div>
</template>

<script>
import { baseMixin } from "@npm/omega-front";
import formatPrice from "../formatPrice";
import { defaultValue } from "./TourDetail";

export default {
  mixins: [baseMixin],

  props: {
    ver4: {
      type: [String, Object],
      default: null
    },

    tour: {
      type: Object,
      default: () => defaultValue
    },

    car11: {
      type: [Number, Object],
      default: 0
    },

    car12: {
      type: [Number, Object],
      default: 0
    }
  },

  computed: {
    carSub() {
      return this.tour.car3 - this.tour.car5 - this.tour.car6;
    },

    aco1() {
      switch (this.ver4) { // depends on backend enum
        // Renewal > 3 years
        case "r3":
          return this.currentValue.par6 * this.carSub;

        // Renewal > 10 years
        case "r10":
          return this.currentValue.par5 * this.carSub;

        // Creation
        case "create":
          return this.currentValue.par4 * this.carSub;
      }

      return 0;
    },

    aco2() {
      return Math.max(0, this.currentValue.par7 * (this.car11 - 2) * this.carSub);
    },

    aco3() {
      return Math.max(0, this.currentValue.par8 * (this.car12 - 2) * this.carSub);
    },

    aco4() {
      return this.aco2 + this.aco3;
    },

    aco5() {
      return this.aco1 + this.aco4;
    },

    aco6() {
      return this.aco5 * 60 / 100;
    }
  },

  filters: { formatPrice }
};
</script>
