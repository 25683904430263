<template>
  <div class="Input BudgetLine" :class="wrapperClass">
    <label v-if="label" class="Input__label">{{ label }}</label>
    <div class="invalid-feedback" v-if="error">{{ error }}</div>
    <div class="Input--suffix" data-suffix="€">
      <input
        type="number"
        min="0"
        step="0.01"
        v-model.number="currentValue"
        :disabled="disabled"
        class="Input__field"
      />
    </div>
  </div>
</template>

<script>
import { baseMixin } from "@npm/omega-front";

export default {
  mixins: [baseMixin]
};
</script>
