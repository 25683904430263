<template>
  <div :class="wrapperClass" class="AmountTotal AmountTotal--res1">
    <div class="AmountTotal__label">{{ label }}</div>
    <div class="AmountTotal__count">{{ result | formatPrice }}</div>
  </div>
</template>

<script>
import { baseMixin } from "@npm/omega-front";
import formatPrice from "../formatPrice";

export default {
  mixins: [baseMixin],

  props: {
    rec5: {
      type: [Number, Object],
      default: 0
    },
    dep1: {
      type: [Number, Object],
      default: 0
    }
  },

  computed: {
    result() {
      return Math.round((this.rec5 - this.dep1) * 100) / 100;
    }
  },

  watch: {
    result: {
      handler(value) {
        this.setValueSynthetic(value);
      },

      immediate: true
    }
  },

  filters: { formatPrice }
};
</script>
