<template>
  <div :class="wrapperClass" class="AmountTotal AmountTotal--eom1">
    <div class="AmountTotal__label">EOM</div>
    <div class="AmountTotal__count">{{ result | formatPrice }}</div>
  </div>
</template>

<script>
import { baseMixin } from "@npm/omega-front";
import formatPrice from "../formatPrice";

export default {
  mixins: [baseMixin],

  props: {
    adh14: {
      type: [Number, Object],
      required: true
    },

    car9: {
      type: [Number, Object],
      default: 0
    }
  },

  computed: {
    result() {
      return this.car9 * this.adh14;
    }
  },

  watch: {
    result: {
      handler(value) {
        this.setValueSynthetic(value);
      },

      immediate: true
    }
  },

  filters: { formatPrice }
};
</script>
