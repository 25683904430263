<template>
  <div class="History">
    <template v-if="updatedAt">
      <span class="History__clock">
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 3a9 9 0 0 0-9 9H1l3.89 3.89.07.14L9 12H6a7 7 0 1 1 2.06 4.94l-1.42 1.42A9 9 0 1 0 13 3zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z" fill="#0DBFB2"></path>
        </svg>
      </span>
      <div class="History__tooltip">
        <p>Modifié le {{ formattedUpdatedAt }}</p>

        <HistoryValue
          :value="formattedInitialValue"
          :href="href"
          caption="Première valeur du tourneur :"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { format as formatFrench } from "@npm/french-date";
import HistoryValue from "./HistoryValue";

export default {
  props: {
    initialValue: {
      type: undefined,
      default: null
    },

    updatedAt: {
      type: String,
      default: ""
    },

    values: {
      type: null,
      default: () => []
    },

    href: {
      type: String,
      default: ""
    }
  },

  computed: {
    formattedUpdatedAt() {
      return formatFrench(new Date(this.updatedAt));
    },

    formattedInitialValue() {
      // For dropdowns and radio groups, fetch the label matching the selected value
      if (typeof this.values === "object" && this.values !== null && !Array.isArray(this.values)) {
        const value = Object.keys(this.values).find(key => this.values[key] === this.initialValue);

        if (value) {
          return value;
        }
      }

      return this.initialValue;
    }
  },

  components: { HistoryValue }
};
</script>
