import clone from "@npm/clone";
import { isValid as isValidFrench, parse as parseFrench } from "@npm/french-date";
import { isValue } from "@npm/omega-front";
import historyMixin from "./historyMixin";
import HistoryTooltip from "./components/HistoryTooltip";

function getDisabledVersion(el, value) {
  if (["input", "select", "textarea"].includes(el.tag)) {
    // Form control node
    el.data.domProps.value = value;
    el.data.attrs.disabled = true;
  } else if (el.componentOptions) {
    // Component
    if (typeof value === "string" && isValidFrench(value)) {
      // Datepicker
      value = parseFrench(value);
    } else if (typeof value === "object" && "car3" in value) {
      // TourDetail
      value = value[el.componentOptions.propsData.name];
    }

    el.componentOptions.propsData.value = value;
    el.componentOptions.propsData.disabled = true;
  } else if (Array.isArray(el.children)) {
    // Something else, we need to go deeper
    el.children = el.children.map(child => getDisabledVersion(child, value));
  }

  return el;
}

export default function withHistory(originalComponent) {
  const component = clone(originalComponent);
  const originalRender = component.render;

  component.mixins.push(historyMixin);

  component.render = function (h) {
    const hasBeforeTourValue = isValue(this.beforeTourValue);

    return h(
      "div",
      {
        class: `HistoryContainer${hasBeforeTourValue ? " HistoryContainer--beforeTour" : ""}`
      },
      [
        ...hasBeforeTourValue
          ? [
            // Disabled doppelganger displaying "before tour" value
            getDisabledVersion(originalRender.call(this, h), this.beforeTourValue),

            // "Initial value" / "Updated at" tooltip ("before tour" values)
            h(HistoryTooltip, {
              props: {
                initialValue: this.beforeTourInitialValue,
                updatedAt: this.beforeTourUpdatedAt,
                values: this.values
              }
            })
          ]
          : [],

        // Original component
        originalRender.call(this, h),

        // "Initial value" / "Updated at" tooltip
        h(HistoryTooltip, {
          props: {
            initialValue: this.initialValue,
            updatedAt: this.updatedAt,
            values: this.values
          }
        })
      ]
    );
  };

  return component;
}
