<template>
  <div class="HistoryContainer">
    <div class="Input" :class="wrapperClass">
      <label class="Input__label">{{ label }}</label>
      <div v-for="(value, index) in localValue" :key="index" class="Input__container">
        <input
          type="text"
          :name="name"
          :disabled="disabled"
          :placeholder="placeholder"
          :value="value"
          @input="update($event.target.value, index)"
          class="Input__field"
          :class="{ 'is-invalid': error }"
        />
        <button
          v-if="index === localValue.length - 1"
          type="button"
          :disabled="disabled"
          @click="localValue = [...localValue, '']"
          class="Button Button--primary Button--small"
        >+</button>
        <button
          v-else
          type="button"
          :disabled="disabled"
          @click="remove(index)"
          class="Button  Button--small"
        >-</button>
      </div>
      <div class="invalid-feedback" v-if="error">{{ error }}</div>
    </div>
    <HistoryTooltip
      :initialValue="initialValue"
      :updatedAt="updatedAt"
    />
  </div>
</template>

<script>
import { baseMixin } from "@npm/omega-front";
import historyMixin from "../historyMixin";
import HistoryTooltip from "./HistoryTooltip";

export default {
  mixins: [baseMixin, historyMixin],

  data() {
    return {
      localValue: [""]
    };
  },

  watch: {
    value: {
      handler(newValue, oldValue) {
        if ((oldValue === null && this.localValue.length <= 1) || (typeof oldValue === "object" && oldValue !== null && "initialValue" in oldValue)) {
          this.localValue = Array.isArray(newValue) && newValue.length > 0 ? newValue : [""];
        }
      },

      immediate: true,
      deep: true
    },

    localValue: {
      handler(values) {
        if (Array.isArray(values)) {
          const newValue = values.filter(value => value !== "");

          if (this.currentValue === null) {
            this.setValueSynthetic(newValue);
          } else {
            this.currentValue = newValue;
          }
        }
      },

      deep: true
    }
  },

  methods: {
    update(value, index) {
      this.$set(this.localValue, index, value);
    },

    remove(index) {
      this.localValue.splice(index, 1);
    }
  },

  components: { HistoryTooltip }
};
</script>
