<template>
  <div class="HistoryContainer HistoryContainer--beforeTour">
    <Eom1
      v-bind="boundProps"
      :car9="beforeTourCar9"
    />
    <Eom1 v-bind="$props" @change="propagateSyntheticUpdate" />
  </div>
</template>

<script>
import { baseMixin } from "@npm/omega-front";
import getHistoryWrapperMixin from "../getHistoryWrapperMixin";
import Eom1 from "./Eom1";

export default {
  mixins: [baseMixin, getHistoryWrapperMixin({ car9: 0 })],

  props: {
    adh14: {
      type: [Number, Object],
      required: true
    }
  },

  components: { Eom1 }
};
</script>
