<template>
  <div class="AssistancePreview">
    <p class="AssistancePreview__title">{{ label }}</p>
    <div class="AssistancePreview__row">
      <label class="AssistancePreview__label">Volet activité</label>
      <div class="AssistancePreview__amount">{{ currentValue.aco1 | formatPrice }}</div>
    </div>
    <div class="AssistancePreview__row AssistancePreview__row--folder">
      <div class="AssistancePreview__row">
        <label class="AssistancePreview__label">Volet Emploi - Comédiens</label>
        <div class="AssistancePreview__amount">{{ currentValue.aco2 | formatPrice }}</div>
      </div>
      <div class="AssistancePreview__row">
        <label class="AssistancePreview__label">Volet Emploi - Techniciens plateau</label>
        <div class="AssistancePreview__amount">{{ currentValue.aco3 | formatPrice }}</div>
      </div>
      <div class="AssistancePreview__row AssistancePreview__row--subTotal">
        <label class="AssistancePreview__label">Total volet emploi</label>
        <div class="AssistancePreview__amount">{{ currentValue.aco4 | formatPrice }}</div>
      </div>
    </div>
    <div class="AssistancePreview__row AssistancePreview__row--subTotal">
      <label class="AssistancePreview__label">Total d'aide théorique</label>
      <div class="AssistancePreview__amount">{{ currentValue.aco5 | formatPrice }}</div>
    </div>
    <div class="AssistancePreview__row AssistancePreview__row--total">
      <label class="AssistancePreview__label">Montant de l'acompte déjà versé</label>
      <div class="AssistancePreview__amount">{{ currentValue.aco6 | formatPrice }}</div>
    </div>
  </div>
</template>

<script>
import { baseMixin } from "@npm/omega-front";
import formatPrice from "../formatPrice";

export default {
  mixins: [baseMixin],
  filters: { formatPrice }
};
</script>
