import Rails from "rails-ujs";
import Vue from "vue/dist/vue.esm";
import { Number, RadioGroup, Text, Textarea } from "@npm/omega-front";
import handleNotification from "./handleNotification";
import withHistory from "./withHistory";
import FundingForm from "./components/FundingForm";
import ValidationForm from "./components/ValidationForm";
import BalanceForm from "./components/BalanceForm";
import ClosingForm from "./components/ClosingForm";
import AmountTotal from "./components/AmountTotal";
import AssistancePreview from "./components/AssistancePreview";
import BudgetLine from "./components/BudgetLine";
import Car9 from "./components/Car9";
import Datepicker from "./components/Datepicker";
import Eom1 from "./components/Eom1";
import FinalAssistancePreview from "./components/FinalAssistancePreview";
import HistoryColumns from "./components/HistoryColumns";
import Ide3 from "./components/Ide3";
import Ide4 from "./components/Ide4";
import Productors from "./components/Productors";
import Res1 from "./components/Res1";
import StaticAssistancePreview from "./components/StaticAssistancePreview";
import TourDetail from "./components/TourDetail";
import HistorizedTextMultiple from "./components/HistorizedTextMultiple";
import HistorizedUploader from "./components/HistorizedUploader";
import HistorizedAmountTotal from "./components/HistorizedAmountTotal";
import HistorizedEom1 from "./components/HistorizedEom1";
import HistorizedRes1 from "./components/HistorizedRes1";

Rails.start();

// Add X-CSRF-Token Header on all ajax requests
(function () {
  const send = XMLHttpRequest.prototype.send;

  XMLHttpRequest.prototype.send = function () {
    this.setRequestHeader("X-CSRF-Token", document.querySelector("meta[name='csrf-token']").getAttribute("content"));

    return send.apply(this, arguments);
  };
}());

// Flash notification
document.addEventListener("DOMContentLoaded", () => {
  handleNotification();
});

const HistorizedDatepicker = withHistory(Datepicker);
const HistorizedNumber = withHistory(Number);
const HistorizedRadioGroup = withHistory(RadioGroup);
const HistorizedText = withHistory(Text);
const HistorizedTextarea = withHistory(Textarea);
const HistorizedBudgetLine = withHistory(BudgetLine);
const HistorizedCar9 = withHistory(Car9);
const HistorizedIde3 = withHistory(Ide3);
const HistorizedIde4 = withHistory(Ide4);
const HistorizedProductors = withHistory(Productors);
const HistorizedTourDetail = withHistory(TourDetail);

// Register custom Omega components
Vue.component("AmountTotal", AmountTotal);
Vue.component("AssistancePreview", AssistancePreview);
Vue.component("BudgetLine", BudgetLine);
Vue.component("Car9", Car9);
Vue.component("Eom1", Eom1);
Vue.component("FinalAssistancePreview", FinalAssistancePreview);
Vue.component("HistoryColumns", HistoryColumns);
Vue.component("Ide3", Ide3);
Vue.component("Ide4", Ide4);
Vue.component("Productors", Productors);
Vue.component("Res1", Res1);
Vue.component("StaticAssistancePreview", StaticAssistancePreview);
Vue.component("TourDetail", TourDetail);
Vue.component("HistorizedDatepicker", HistorizedDatepicker);
Vue.component("HistorizedNumber", HistorizedNumber);
Vue.component("HistorizedRadioGroup", HistorizedRadioGroup);
Vue.component("HistorizedText", HistorizedText);
Vue.component("HistorizedTextarea", HistorizedTextarea);
Vue.component("HistorizedTextMultiple", HistorizedTextMultiple);
Vue.component("HistorizedUploader", HistorizedUploader);
Vue.component("HistorizedAmountTotal", HistorizedAmountTotal);
Vue.component("HistorizedBudgetLine", HistorizedBudgetLine);
Vue.component("HistorizedCar9", HistorizedCar9);
Vue.component("HistorizedEom1", HistorizedEom1);
Vue.component("HistorizedIde3", HistorizedIde3);
Vue.component("HistorizedIde4", HistorizedIde4);
Vue.component("HistorizedProductors", HistorizedProductors);
Vue.component("HistorizedRes1", HistorizedRes1);
Vue.component("HistorizedTourDetail", HistorizedTourDetail);

/**
 * Parses URL for entity id, if any
 *
 * @return {Number|null}
 */
function getIdFromURL() {
  const match = window.location.pathname.match(/\/(\d+)/);

  return match && match.length > 0 ? parseInt(match.pop()) : null;
}

/**
 * Renders an Omega form
 *
 * @param {String} domSelector
 * @param {Object} component
 * @param {Object} customComponents
 */
function renderOmega(domSelector, component, customComponents) {
  new Vue({
    render: h => h(component, {
      props: {
        id: getIdFromURL(),
        customComponents
      }
    })
  }).$mount(domSelector);
}

window.addEventListener("DOMContentLoaded", event => {
  if (document.querySelector("#funding-phase1")) {
    // Funding request (phase 1)
    renderOmega("#funding-phase1", FundingForm, {
      AmountTotal,
      BudgetLine,
      Car9,
      Eom1,
      Ide3,
      Ide4,
      Productors,
      Res1,
      TourDetail
    });
  } else if (document.querySelector("#funding-phase2")) {
    // Funding validation (phase 2)
    renderOmega("#funding-phase2", ValidationForm, {
      AmountTotal,
      AssistancePreview,
      Eom1,
      Res1,
      HistorizedDatepicker,
      HistorizedNumber,
      HistorizedRadioGroup,
      HistorizedText,
      HistorizedTextarea,
      HistorizedTextMultiple,
      HistorizedUploader,
      HistorizedBudgetLine,
      HistorizedCar9,
      HistorizedIde3,
      HistorizedIde4,
      HistorizedProductors,
      HistorizedTourDetail
    });
  } else if (document.querySelector("#funding-phase3")) {
    // Funding balance (phase 3)
    renderOmega("#funding-phase3", BalanceForm, {
      HistoryColumns,
      HistorizedDatepicker,
      HistorizedNumber,
      HistorizedRadioGroup,
      HistorizedText,
      HistorizedTextarea,
      HistorizedTextMultiple,
      HistorizedUploader,
      HistorizedAmountTotal,
      HistorizedBudgetLine,
      HistorizedCar9,
      HistorizedEom1,
      HistorizedIde3,
      HistorizedIde4,
      HistorizedProductors,
      HistorizedRes1,
      HistorizedTourDetail
    });
  } else if (document.querySelector("#funding-phase4")) {
    // Funding closing (phase 4)
    renderOmega("#funding-phase4", ClosingForm, {
      AmountTotal,
      FinalAssistancePreview,
      HistoryColumns,
      StaticAssistancePreview,
      HistorizedDatepicker,
      HistorizedNumber,
      HistorizedRadioGroup,
      HistorizedText,
      HistorizedTextarea,
      HistorizedTextMultiple,
      HistorizedUploader,
      HistorizedAmountTotal,
      HistorizedBudgetLine,
      HistorizedCar9,
      HistorizedEom1,
      HistorizedIde3,
      HistorizedIde4,
      HistorizedProductors,
      HistorizedRes1,
      HistorizedTourDetail
    });
  }

  // Only show three shows per year blocks by default and allow to
  // toggle visibility of older ones.
  if (document.querySelector(".p-users-edit__form")) {
    const allShowCounts = document.querySelectorAll(".p-users-edit__form .show_per_years");

    allShowCounts.forEach((e, i) => {
      if (i > 2) {
        e.style.display = "none";
      }
    });

    const showAllLink = document.getElementById("js-show-all-show-per-years-history");

    showAllLink.style.display = null;

    showAllLink.addEventListener("click", e => {
      e.preventDefault();
      allShowCounts.forEach(e => e.style.display = null);
      showAllLink.style.display = "none";
    });
  }
});
