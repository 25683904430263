<template>
  <div class="HistoryContainer HistoryContainer--beforeTour">
    <Res1
      v-bind="boundProps"
      :rec5="beforeTourRec5"
      :dep1="beforeTourDep1"
    />
    <Res1 v-bind="$props" @change="propagateSyntheticUpdate" />
  </div>
</template>

<script>
import { baseMixin } from "@npm/omega-front";
import getHistoryWrapperMixin from "../getHistoryWrapperMixin";
import Res1 from "./Res1";

export default {
  mixins: [baseMixin, getHistoryWrapperMixin({ rec5: 0, dep1: 0 })],
  components: { Res1 }
};
</script>
