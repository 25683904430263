<template>
  <div class="HistoryColumns" :class="cssClass">
    <div class="HistoryColumns__head">{{ label1 }}</div>
    <div class="HistoryColumns__head">{{ label2 }}</div>
  </div>
</template>

<script>
export default {
  props: {
    label1: {
      type: String,
      required: true
    },

    label2: {
      type: String,
      required: true
    },

    cssClass: {
      type: String,
      default: ""
    }
  }
};
</script>
