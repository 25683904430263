import capitalize from "@npm/capitalize";
import historyMixin from "./historyMixin";
import eventBus from "./eventBus";

/**
 * @param {Object} [fields]
 *
 * @return {Object}
 */
export default function getHistoryWrapperMixin(fields = {}) {
  const keys = Object.keys(fields);

  const data = function () {
    return keys.reduce((acc, key) => ({
      ...acc,
      [`beforeTour${capitalize(key)}`]: fields[key]
    }), {});
  };

  const methods = keys.reduce((acc, key) => ({
    ...acc,
    [`setBeforeTour${capitalize(key)}`]: function (value) {
      this[`beforeTour${capitalize(key)}`] = value;
    }
  }), {
    propagateSyntheticUpdate(...args) {
      this.$emit("change", ...args);
    }
  });

  const created = function () {
    keys.forEach(key => {
      eventBus.$on(`beforeTour.${key}`, this[`setBeforeTour${capitalize(key)}`]);
    });
  };

  const beforeDestroy = function () {
    keys.forEach(key => {
      eventBus.$on(`beforeTour.${key}`, this[`setBeforeTour${capitalize(key)}`]);
    });
  };

  return {
    mixins: [historyMixin],

    props: keys.reduce((acc, key) => ({
      ...acc,
      [key]: {
        type: null,
        default: fields[key]
      }
    }), {}),

    data,

    computed: {
      boundProps() {
        return Object.keys(this.$props).reduce((acc, key) => ({
          ...acc,
          ...keys.includes(key) ? {} : { [key]: this.$props[key] }
        }), {});
      }
    },

    methods,
    created,
    beforeDestroy
  };
}
